import Classes from "@/base/project/classes.js";
import TeacherClasses from "@/base/project/teacher-classes.js";
import TeacherClassesScores from "@/base/project/teacher-classes-scores.js";

import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isClassesLoaded: false,
    classes: [],
    // TODO: remove with settings.features.CLASSES_LIMIT later
    maxClasses: null,
    hasPrimaryClasses: false,

    classesStudentsById: {},
    classesTeachersById: {},
    classesDetailsById: {},
    classesStudents: {},
    classesStudentsScoresByRange: {},
    classesScoresByDailyJuices: {},
    classesScoresByAssignments: {},

    classAverageScoresById: {},
    classRatesById: {},
    classesAnnouncementsById: {},
    classesSponsorsById: {},
    classesStoriesCategoriesByRange: {},
    classesAverageScoresByStandards: {},
    classesPerformanceByRange: {},
    classesStatsByLearningStandards: {},

    selectedClassId: -1,
    statsRange: "today",

    isStudentsWithPendingStatusLoaded: false,
    isStudentsWithPendingStatusLoading: false,
    studentsWithPendingStatus: [],
    studentsWithPendingStatusPage: 0,
    studentsWithPendingStatusHasMorePages: false,

    isStudentsWithForgottenPasswordsLoaded: false,
    studentsWithForgottenPasswords: [],

    derosteredClasses: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_CLASSES_LOADING: {
            return {
                ...state,
                classes: [],
                isClassesLoaded: false,
            };
        }

        case types.SET_TEACHER_CLASSES: {
            const {
                classes,
                maxClasses,
                hasPrimaryClasses,
            } = action.payload;

            return {
                ...state,
                classes,
                maxClasses,
                hasPrimaryClasses,
                isClassesLoaded: true,
            };
        }

        /* --- */

        case types.SET_TEACHER_CLASS_PROGRESS_REPORT: {
            const { classId, hasNewProgressReport } = action.payload;

            const classes = Classes.setClassNewProgressReport(
                state.classes,
                classId,
                hasNewProgressReport,
            );

            return {
                ...state,
                classes,
            };
        }

        /* --- */

        case types.SET_TEACHER_CLASSES_DETAILS_BY_ID_LOADING: {
            const { classId } = action.payload;

            return {
                ...state,
                classesDetailsById: {
                    ...state.classesDetailsById,
                    [classId]: {
                        isLoading: true,
                        data: null,
                        error: "",
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_DETAILS_BY_ID: {
            const { classId, data, error } = action.payload;

            return {
                ...state,
                classesDetailsById: {
                    ...state.classesDetailsById,
                    [classId]: {
                        isLoading: false,
                        data,
                        error,
                    },
                },
            };
        }

        /* ------ */

        case types.SET_TEACHER_CLASSES_TEACHERS_BY_ID_LOADING: {
            const { classId } = action.payload;

            return {
                ...state,
                classesTeachersById: {
                    ...state.classesTeachersById,
                    [classId]: {
                        data: null,
                        error: "",
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_PRIMARY_TEACHER: {
            const { teacherId, classId } = action.payload;

            const prevData = state.classesTeachersById?.[classId] || {};
            const teachers = prevData?.data || [];

            const data = [];

            for (let i = 0; i < teachers.length; i += 1) {
                const teacher = {
                    ...teachers[i],
                    isPrimaryTeacher: false,
                };

                if (teacher.id === teacherId) {
                    teacher.isPrimaryTeacher = true;
                }

                data.push(teacher);
            }

            return {
                ...state,
                classesTeachersById: {
                    ...state.classesTeachersById,
                    [classId]: {
                        ...prevData,
                        data,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_TEACHERS_BY_ID: {
            const { classId, data, error } = action.payload;

            return {
                ...state,
                classesTeachersById: {
                    ...state.classesTeachersById,
                    [classId]: {
                        data,
                        error,
                        isLoading: false,
                    },
                },
            };
        }

        /* ------ */

        case types.SET_LOADING_TEACHER_CLASS_STUDENTS: {
            return {
                ...state,
                classesStudentsById: {
                    ...state.classesStudentsById,
                    [action.payload.classId]: {
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_STUDENTS: {
            return {
                ...state,
                classesStudentsById: {
                    ...state.classesStudentsById,
                    [action.payload.classId]: {
                        isLoading: false,
                        students: action.payload.students,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_STUDENTS_LOADING: {
            return {
                ...state,
                classesStudents: {
                    classes: null,
                    error: "",
                    isLoading: true,
                },
            };
        }

        case types.SET_TEACHER_CLASSES_STUDENTS: {
            const { classes, error } = action.payload;

            return {
                ...state,
                classesStudents: {
                    classes,
                    error,
                    isLoading: false,
                    isLoaded: true,
                },
            };
        }

        case types.CLEAR_CLASSES_SCORES: {
            return {
                ...state,
                classRatesById: {},
                classAverageScoresById: {},
                classesAverageScoresByStandards: {},
            };
        }

        case types.SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS_LOADING: {
            const { range } = action.payload;

            return {
                ...state,
                classesAverageScoresByStandards: {
                    ...state.classesAverageScoresByStandards,
                    [range]: {
                        isLoading: true,
                        data: null,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_AVERAGE_SCORES_BY_STANDARDS: {
            const { range, data, error } = action.payload;

            return {
                ...state,
                classesAverageScoresByStandards: {
                    ...state.classesAverageScoresByStandards,
                    [range]: {
                        isLoading: false,
                        data,
                        error,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_AVERAGE_SCORES_LOADING: {
            const { classId, range, category } = action.payload;

            return {
                ...state,
                classAverageScoresById: {
                    ...state.classAverageScoresById,
                    [classId]: {
                        ...state.classAverageScoresById[classId],
                        [category]: {
                            ...state.classAverageScoresById[classId]?.[category],
                            [range]: {
                                isLoading: true,
                                data: null,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_AVERAGE_SCORES: {
            const {
                range,
                category,
                classId,
                data,
                error,
            } = action.payload;

            return {
                ...state,
                classAverageScoresById: {
                    ...state.classAverageScoresById,
                    [classId]: {
                        ...state.classAverageScoresById[classId],
                        [category]: {
                            ...state.classAverageScoresById[classId]?.[category],
                            [range]: {
                                isLoading: false,
                                data,
                                error,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_RATE_DETAILS_LOADING: {
            const { classId, range } = action.payload;

            return {
                ...state,
                classRatesById: {
                    ...state.classRatesById,
                    [classId]: {
                        ...state.classRatesById[classId],
                        [range]: {
                            ...state.classRatesById[classId]?.[range],
                            isDetailsLoading: true,
                            details: null,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_RATE_DETAILS: {
            const { classId, range, details } = action.payload;

            return {
                ...state,
                classRatesById: {
                    ...state.classRatesById,
                    [classId]: {
                        ...state.classRatesById[classId],
                        [range]: {
                            ...state.classRatesById[classId]?.[range],
                            isDetailsLoading: false,
                            details: [
                                ...details,
                            ],
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_RATE_LOADING: {
            const { classId, range } = action.payload;

            return {
                ...state,
                classRatesById: {
                    ...state.classRatesById,
                    [classId]: {
                        ...state.classRatesById[classId],
                        [range]: {
                            ...state.classRatesById[classId]?.[range],
                            isLoading: true,
                            data: null,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_RATE: {
            const {
                classId,
                range,
                data,
                error,
            } = action.payload;

            return {
                ...state,
                classRatesById: {
                    ...state.classRatesById,
                    [classId]: {
                        ...state.classRatesById[classId],
                        [range]: {
                            ...state.classRatesById[classId]?.[range],
                            isLoading: false,
                            data,
                            error,
                        },
                    },
                },
            };
        }

        /* ------- */

        case types.SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS_LOADING: {
            const { classId, range } = action.payload;

            let scoresByClassId = {
                [range]: {
                    isLoading: true,
                    data: null,
                },
            };

            if (state.classesScoresByAssignments?.[classId]) {
                scoresByClassId = {
                    ...state.classesScoresByAssignments[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesScoresByAssignments: {
                    ...state.classesScoresByAssignments,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.SET_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS: {
            const {
                classId,
                range,
                data,
                error,
                hasMoreBefore,
                hasMoreAfter,
            } = action.payload;

            let scoresByClassId = {
                [range]: {
                    data,
                    error,
                    isLoading: false,
                    hasMoreBefore,
                    hasMoreAfter,
                },
            };

            if (state.classesScoresByAssignments?.[classId]) {
                scoresByClassId = {
                    ...state.classesScoresByAssignments[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesScoresByAssignments: {
                    ...state.classesScoresByAssignments,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.UPDATE_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS: {
            const {
                classId,
                range,
                data,
                error,
                hasMoreBefore,
                hasMoreAfter,
            } = action.payload;

            const classData = state.classesScoresByAssignments[classId] || {};
            const rangeData = classData[range] || {};
            const rangeStudentsData = rangeData?.data?.students || [];

            const students = data?.students || [];

            const newRangeStudentsData = TeacherClasses.updateStudentsAssignmentsScores(
                rangeStudentsData,
                students,
            );

            let scoresByClassId = {
                [range]: {
                    data: {
                        ...data,
                        students: newRangeStudentsData,
                    },
                    error,
                    isLoading: false,
                    hasMoreBefore,
                    hasMoreAfter,
                },
            };

            if (state.classesScoresByAssignments?.[classId]) {
                scoresByClassId = {
                    ...state.classesScoresByAssignments[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesScoresByAssignments: {
                    ...state.classesScoresByAssignments,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_ASSIGNMENTS: {
            const {
                classId,
                range,
                data,
            } = action.payload;

            const classData = state.classesScoresByAssignments[classId] || {};
            const rangeData = classData[range] || {};
            const rangeStudentsData = rangeData?.data?.students || [];

            const students = data?.students || [];

            const newRangeStudentsData = TeacherClasses.clearStudentsAssignmentsScoresUpdatedStatus(
                rangeStudentsData,
                students,
            );

            let scoresByClassId = {
                [range]: {
                    ...rangeData,
                    data: {
                        ...data,
                        students: newRangeStudentsData,
                    },
                },
            };

            if (state.classesScoresByAssignments?.[classId]) {
                scoresByClassId = {
                    ...state.classesScoresByAssignments[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesScoresByAssignments: {
                    ...state.classesScoresByAssignments,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.CLEAR_TEACHER_CLASSES_SCORES_BY_ASSIGNMENTS: {
            return {
                ...state,
                classesScoresByAssignments: {},
            };
        }

        /* --- */

        case types.SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_LOADING: {
            const { classId, range } = action.payload;

            let scoresByClassId = {
                [range]: {
                    isLoading: true,
                    data: null,
                },
            };

            if (state.classesScoresByDailyJuices?.[classId]) {
                scoresByClassId = {
                    ...state.classesScoresByDailyJuices[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesScoresByDailyJuices: {
                    ...state.classesScoresByDailyJuices,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_STUDENT_ANSWERED: {
            const {
                juiceId,
                studentId,
            } = action.payload;

            const scores = TeacherClassesScores.setStudentDailyJuiceScoreUpdatedStatus(
                state.classesScoresByDailyJuices,
                juiceId,
                studentId,
            );

            return {
                ...state,
                classesScoresByDailyJuices: scores,
            };
        }

        case types.CLEAR_TEACHER_CLASS_SCORES_BY_DAILY_JUICES_STUDENT_ANSWERED_UPDATED_STATUS: {
            const {
                juiceId,
                studentId,
            } = action.payload;

            const scores = TeacherClassesScores.clearStudentDailyJuiceScoreUpdatedStatus(
                state.classesScoresByDailyJuices,
                juiceId,
                studentId,
            );

            return {
                ...state,
                classesScoresByDailyJuices: scores,
            };
        }

        case types.SET_TEACHER_CLASS_SCORES_BY_DAILY_JUICES: {
            const {
                classId,
                range,
                data,
                error,
            } = action.payload;

            let scoresByClassId = {
                [range]: {
                    isLoading: false,
                    data,
                    error,
                },
            };

            if (state.classesScoresByDailyJuices?.[classId]) {
                scoresByClassId = {
                    ...state.classesScoresByDailyJuices[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesScoresByDailyJuices: {
                    ...state.classesScoresByDailyJuices,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.CLEAR_TEACHER_CLASSES_SCORES_BY_DAILY_JUICES: {
            return {
                ...state,
                classesScoresByDailyJuices: {},
            };
        }

        /* ------- */

        case types.CLEAR_STUDENTS_SCORES_BY_RANGE: {
            return {
                ...state,
                classesStudentsScoresByRange: {},
            };
        }

        case types.SET_TEACHER_CLASS_SCORES_BY_RANGE_LOADING: {
            const { classId, range } = action.payload;

            let scoresByClassId = {
                [range]: {
                    isLoading: true,
                    data: null,
                },
            };

            if (state.classesStudentsScoresByRange?.[classId]) {
                scoresByClassId = {
                    ...state.classesStudentsScoresByRange[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesStudentsScoresByRange: {
                    ...state.classesStudentsScoresByRange,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.SET_TEACHER_CLASS_SCORES_BY_RANGE: {
            const {
                classId,
                range,
                data,
                error,
            } = action.payload;

            let scoresByClassId = {
                [range]: {
                    isLoading: false,
                    data,
                    error,
                },
            };

            if (state.classesStudentsScoresByRange?.[classId]) {
                scoresByClassId = {
                    ...state.classesStudentsScoresByRange[classId],
                    ...scoresByClassId,
                };
            }

            return {
                ...state,
                classesStudentsScoresByRange: {
                    ...state.classesStudentsScoresByRange,
                    [classId]: scoresByClassId,
                },
            };
        }

        case types.UPDATE_TEACHER_CLASS_SCORES_BY_DAILY_JUICE: {
            const {
                classId,
                range,
                data,
                error,
            } = action.payload;

            const classData = state.classesScoresByDailyJuices[classId] || {};
            const rangeData = classData[range] || {};
            const rangeStudentsData = rangeData?.data?.students || [];

            const students = data?.students || [];

            const newStudents = TeacherClasses.updateStudentsScores(
                rangeStudentsData,
                students,
            );

            return {
                ...state,
                classesScoresByDailyJuices: {
                    ...state.classesScoresByDailyJuices,
                    [classId]: {
                        ...classData,
                        [range]: {
                            ...classData[range],
                            ...rangeData,
                            data: {
                                ...rangeData.data,
                                students: newStudents,
                            },
                            error,
                        },
                    },
                },
            };
        }

        case types.CLEAR_UPDATED_STATUS_TEACHER_CLASS_SCORES_BY_DAILY_JUICE: {
            const {
                classId,
                range,
                data,
            } = action.payload;

            const classData = state.classesScoresByDailyJuices[classId] || {};
            const rangeData = classData[range] || {};
            const rangeStudentsData = rangeData?.data?.students || [];

            const students = data?.students || [];

            const newRangeStudentsData = TeacherClasses.clearStudentsScoresUpdatedStatus(
                rangeStudentsData,
                students,
            );

            const studentsWithAchievements = TeacherClasses.clearStudentsAchievementsUpdatedStatus(
                newRangeStudentsData,
                students,
            );

            return {
                ...state,
                classesScoresByDailyJuices: {
                    ...state.classesScoresByDailyJuices,
                    [classId]: {
                        ...classData,
                        [range]: {
                            ...classData[range],
                            ...rangeData,
                            data: {
                                ...rangeData.data,
                                students: studentsWithAchievements,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS: {
            const { classesSponsors } = action.payload;

            const classesSponsorsById = {};

            for (let i = 0; i < classesSponsors.length; i += 1) {
                const sponsor = classesSponsors[i];

                classesSponsorsById[sponsor.id] = {
                    isLoading: false,
                    data: sponsor.data || null,
                    error: sponsor.error || "",
                };
            }

            return {
                ...state,
                classesSponsorsById,
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_LOADING: {
            const { classes } = action.payload;

            const classesSponsorsById = {};

            for (let i = 0; i < classes.length; i += 1) {
                const cl = classes[i];

                classesSponsorsById[cl.id] = {
                    isLoading: true,
                    data: null,
                    error: "",
                };
            }

            return {
                ...state,
                classesSponsorsById,
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_BY_ID_SAVING: {
            const { classId, isSaving, error } = action.payload;

            return {
                ...state,
                classesSponsorsById: {
                    ...state.classesSponsorsById,
                    [classId]: {
                        ...state.classesSponsorsById?.[classId],
                        isSaving,
                        error,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_CONTENT_BY_ID: {
            const { classId, text, size } = action.payload;

            return {
                ...state,
                classesSponsorsById: {
                    ...state.classesSponsorsById,
                    [classId]: {
                        ...state.classesSponsorsById?.[classId],
                        data: {
                            ...state.classesSponsorsById?.[classId]?.data,
                            sponsorContent: text,
                            sponsorContentSize: size,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_CLEAR: {
            const { classId } = action.payload;

            return {
                ...state,
                classesSponsorsById: {
                    ...state.classesSponsorsById,
                    [classId]: {
                        ...state.classesSponsorsById?.[classId],
                        data: {
                            ...state.classesSponsorsById?.[classId]?.data,
                            images: [],
                            sponsorImageUrl: "",
                            sponsorImageId: null,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID_LOADING: {
            const { classId, isLoading } = action.payload;

            return {
                ...state,
                classesSponsorsById: {
                    ...state.classesSponsorsById,
                    [classId]: {
                        ...state.classesSponsorsById?.[classId],
                        data: {
                            ...state.classesSponsorsById?.[classId]?.data,
                            isImageLoading: isLoading,
                        },
                        error: "",
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_IMAGE_BY_ID: {
            const { classId, data, error } = action.payload;

            return {
                ...state,
                classesSponsorsById: {
                    ...state.classesSponsorsById,
                    [classId]: {
                        ...state.classesSponsorsById?.[classId],
                        data: {
                            ...state.classesSponsorsById?.[classId]?.data,
                            ...data,
                            isImageLoading: false,
                        },
                        error,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_BY_ID_LOADING: {
            const { classId } = action.payload;

            return {
                ...state,
                classesSponsorsById: {
                    ...state.classesSponsorsById,
                    [classId]: {
                        isLoading: true,
                        data: null,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_SPONSORS_BY_ID: {
            const { classId, data, error } = action.payload;

            return {
                ...state,
                classesSponsorsById: {
                    ...state.classesSponsorsById,
                    [classId]: {
                        isLoading: false,
                        data,
                        error,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_ANNOUNCEMENTS: {
            const { classesAnnouncements } = action.payload;

            const classesAnnouncementsById = {};

            for (let i = 0; i < classesAnnouncements.length; i += 1) {
                const cl = classesAnnouncements[i];

                classesAnnouncementsById[cl.id] = {
                    isLoading: false,
                    data: cl.data,
                    error: cl.error,
                };
            }

            return {
                ...state,
                classesAnnouncementsById,
            };
        }

        case types.SET_TEACHER_CLASSES_ANNOUNCEMENTS_LOADING: {
            const { classes } = action.payload;

            const classesAnnouncementsById = {};

            for (let i = 0; i < classes.length; i += 1) {
                const cl = classes[i];

                classesAnnouncementsById[cl.id] = {
                    isLoading: true,
                    data: null,
                    error: "",
                };
            }

            return {
                ...state,
                classesAnnouncementsById,
            };
        }

        case types.SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID_LOADING: {
            const { classId } = action.payload;

            return {
                ...state,
                classesAnnouncementsById: {
                    ...state.classesAnnouncementsById,
                    [classId]: {
                        isLoading: true,
                        data: null,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_ANNOUNCEMENT_BY_ID_SAVING: {
            const { classId, isSaving, error } = action.payload;

            return {
                ...state,
                classesAnnouncementsById: {
                    ...state.classesAnnouncementsById,
                    [classId]: {
                        ...state.classesAnnouncementsById?.[classId],
                        isSaving,
                        error,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_ANNOUNCEMENT_CONTENT_BY_ID: {
            const { classId, text, size } = action.payload;

            return {
                ...state,
                classesAnnouncementsById: {
                    ...state.classesAnnouncementsById,
                    [classId]: {
                        ...state.classesAnnouncementsById?.[classId],
                        data: {
                            ...state.classesAnnouncementsById?.[classId]?.data,
                            announcementContent: text,
                            announcementContentSize: size,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_ANNOUNCEMENTS_BY_ID: {
            const { classId, data, error } = action.payload;

            return {
                ...state,
                classesAnnouncementsById: {
                    ...state.classesAnnouncementsById,
                    [classId]: {
                        isLoading: false,
                        data,
                        error,
                    },
                },
            };
        }

        case types.CLEAR_TEACHER_CLASSES_CATEGORIES: {
            return {
                ...state,
                classesStoriesCategoriesByRange: {},
            };
        }

        case types.SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE_LOADING: {
            const { range } = action.payload;

            return {
                ...state,
                classesStoriesCategoriesByRange: {
                    ...state.classesStoriesCategoriesByRange,
                    [range]: {
                        isLoaded: false,
                        categories: [],
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_CATEGORIES_BY_RANGE: {
            const { categories, range } = action.payload;

            return {
                ...state,
                classesStoriesCategoriesByRange: {
                    ...state.classesStoriesCategoriesByRange,
                    [range]: {
                        isLoaded: true,
                        categories,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASS_CODE: {
            const { classId, classCode } = action.payload;

            const classes = Classes.setClassCodeById(state.classes, classId, classCode);

            return {
                ...state,
                classes,
            };
        }

        case types.SET_TEACHER_CLASS_END_DATE: {
            const { classId, endDate } = action.payload;

            const classes = Classes.setClassEndDateById(state.classes, classId, endDate);

            return {
                ...state,
                classes,
            };
        }

        case types.SET_TEACHER_SELECTED_CLASS: {
            return {
                ...state,
                selectedClassId: action.payload.selectedClassId || -1,
            };
        }

        case types.SET_TEACHER_STATS_RANGE: {
            return {
                ...state,
                statsRange: action.payload.range || null,
            };
        }

        case types.SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS: {
            return {
                ...state,
                isStudentsWithForgottenPasswordsLoaded: true,
                studentsWithForgottenPasswords: action.payload.students || [],
            };
        }

        case types.SET_TEACHER_STUDENTS_WITH_PENDING_STATUS: {
            return {
                ...state,
                isStudentsWithPendingStatusLoaded: true,
                isStudentsWithPendingStatusLoading: false,
                studentsWithPendingStatus: action.payload.students || [],
                studentsWithPendingStatusPage: action.payload.page || 0,
                studentsWithPendingStatusHasMorePages: action.payload.hasMore || false,
            };
        }

        case types.SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING: {
            return {
                ...state,
                isStudentsWithPendingStatusLoading: true,
            };
        }

        case types.CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS: {
            return {
                ...state,
                isStudentsWithPendingStatusLoaded: false,
                isStudentsWithPendingStatusLoading: false,
                studentsWithPendingStatus: [],
                studentsWithPendingStatusPage: 0,
                studentsWithPendingStatusHasMorePages: false,
            };
        }

        case types.DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID: {
            const newStudents = state.studentsWithPendingStatus
                .filter((s) => s.id !== action.payload.studentId);

            return {
                ...state,
                studentsWithPendingStatus: newStudents,
            };
        }

        case types.DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD: {
            const { studentId } = action.payload;

            if (!studentId) {
                return state;
            }

            const newStudents = state.studentsWithForgottenPasswords
                .filter((s) => s.id !== studentId);

            return {
                ...state,
                isStudentsWithForgottenPasswordsLoaded: true,
                studentsWithForgottenPasswords: newStudents,
            };
        }

        /* --- */

        case types.SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE_LOADING: {
            const { range, categories } = action.payload;

            const performanceByRange = state.classesPerformanceByRange?.[range] || {};

            return {
                ...state,
                classesPerformanceByRange: {
                    ...state.classesPerformanceByRange,
                    [range]: {
                        ...performanceByRange,
                        [categories]: {
                            data: null,
                            error: "",
                            isLoading: true,
                            isUpdated: false,
                        },
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_PERFORMANCE_BY_RANGE: {
            const {
                range,
                categories,
                data,
                error,
            } = action.payload;

            const performanceByRange = state.classesPerformanceByRange?.[range] || {};

            return {
                ...state,
                classesPerformanceByRange: {
                    ...state.classesPerformanceByRange,
                    [range]: {
                        ...performanceByRange,
                        [categories]: {
                            data,
                            error,
                            isLoading: false,
                            isUpdated: false,
                        },
                    },
                },
            };
        }

        case types.UPDATE_TEACHER_CLASSES_PERFORMANCE_BY_RANGE: {
            const {
                range,
                categories,
                data,
                error,
            } = action.payload;

            const performanceByRange = state.classesPerformanceByRange?.[range] || {};

            return {
                ...state,
                classesPerformanceByRange: {
                    ...state.classesPerformanceByRange,
                    [range]: {
                        ...performanceByRange,
                        [categories]: {
                            data,
                            error,
                            isLoading: false,
                            isUpdated: true,
                        },
                    },
                },
            };
        }

        case types.CLEAR_UPDATED_STATUS_TEACHER_CLASSES_PERFORMANCE_BY_RANGE: {
            const {
                range,
                categories,
            } = action.payload;

            const performanceByRange = state.classesPerformanceByRange?.[range] || {};
            const performanceCategories = performanceByRange[categories];

            if (!performanceCategories) {
                return state;
            }

            return {
                ...state,
                classesPerformanceByRange: {
                    ...state.classesPerformanceByRange,
                    [range]: {
                        ...performanceByRange,
                        [categories]: {
                            ...performanceCategories,
                            isUpdated: false,
                        },
                    },
                },
            };
        }

        case types.CLEAR_TEACHER_CLASSES_PERFORMANCE: {
            return {
                ...state,
                classesPerformanceByRange: {},
            };
        }

        /* --- */

        case types.SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS_LOADING: {
            const { range } = action.payload;

            return {
                ...state,
                classesStatsByLearningStandards: {
                    ...state.classesStatsByLearningStandards,
                    [range]: {
                        data: null,
                        error: "",
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS: {
            const { range, data, error } = action.payload;

            return {
                ...state,
                classesStatsByLearningStandards: {
                    ...state.classesStatsByLearningStandards,
                    [range]: {
                        data,
                        error,
                        isLoading: false,
                    },
                },
            };
        }

        case types.CLEAR_TEACHER_CLASSES_STATS_BY_LEARNING_STANDARDS: {
            return {
                ...state,
                classesStatsByLearningStandards: {},
            };
        }

        /* --- */

        case types.SET_TEACHER_DEROSTERED_CLASSES_LOADING: {
            return {
                ...state,
                derosteredClasses: {
                    data: null,
                    error: "",
                    isLoaded: false,
                },
            };
        }

        case types.SET_TEACHER_DEROSTERED_CLASSES: {
            const { data, error } = action.payload;

            return {
                ...state,
                derosteredClasses: {
                    data,
                    error,
                    isLoaded: true,
                },
            };
        }

        case types.REMOVE_TEACHER_CLASS_FROM_DEROSTERED_CALSSES: {
            if (!state.derosteredClasses.isLoaded) {
                return state;
            }

            const { classId } = action.payload;

            const data = (state.derosteredClasses.data || []).filter((d) => d.id !== classId);

            return {
                ...state,
                derosteredClasses: {
                    ...state.derosteredClasses,
                    data,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
